<template>
    <div class="workshopsDetail__venue">
        <div>
            <base-countdown
                v-if="workshop.event_date"
                :end="`${workshop.event_date} ${workshop.start_time}`"
                :end-date="workshop.event_date"
            />
        </div>
        <div class="workshopsDetail__venue--detail">
            <ul>
                <li>
                    <div class="icon">
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 512 512"
                        >
                            <use xlink:href="#workshop-time-icon"></use>
                        </svg>
                    </div>
                    <span class="ltr"
                        >{{ workshop.start_time }} -
                        {{ workshop.end_time }}</span
                    >
                </li>
                <li>
                    <div class="icon">
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 384 512"
                        >
                            <use xlink:href="#workshop-location-icon"></use>
                        </svg>
                    </div>
                    <!-- <span>{{ workshop.location }}</span> -->
                    <span
                        >{{
                            workshop.location_type == 'online'
                                ? $t('Online')
                                : workshop.location
                        }}
                    </span>
                </li>
            </ul>
            <div v-if="showRegistration">
                <a
                    href="javascript:void(0)"
                    @click="registerNow"
                    v-if="!registration"
                    ><span>{{ $t('Register now') }}</span>
                    <div class="icon">
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 123.66 72.4"
                        >
                            <use xlink:href="#register-arrow-icon"></use>
                        </svg>
                    </div>
                </a>
                <a href="javascript:void(0)" @click="registerNow" v-else
                    ><span>{{ $t('Cancel Registration') }}</span>
                    <div class="icon">
                        <svg
                            fill="currentColor"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 123.66 72.4"
                        >
                            <use xlink:href="#register-arrow-icon"></use>
                        </svg>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { WorkshopHelper } from '@/common/crud-helpers/workshop'
import cloneDeep from 'lodash/cloneDeep'

export default {
    components: {},
    props: {
        workshop: Object,
    },
    data() {
        return {
            registration: false,
            showRegistration: true,
        }
    },
    created() {
        const curent = new Date()
        const eventDate = new Date(this.workshop.event_date)
        if (curent > eventDate) {
            this.showRegistration = false
        }
        this.registration = cloneDeep(this.workshop.i_registration)
    },
    methods: {
        registerNow() {
            WorkshopHelper.userRegistration({
                uuid: this.uuid(this.workshop.uuid),
            }).then(resp => {
                if (resp.data.id) {
                    this.registration = true
                } else {
                    this.registration = false
                }
            })
        },
    },
    watch: {
        workshop: {
            handler() {
                this.registration = cloneDeep(this.workshop.i_registration)
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.workshopsDetail {
    &__venue {
        padding: rem(45px) rem(115px) rem(45px) rem(65px);
        margin-top: rem(30px);
        /* background: rgba(245,225,44,.2);*/
       background:linear-gradient(160deg, var(--primary) 40%, var(--green) 100%);
        @include flex(center, space-between);
        -webkit-box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.7);
        -moz-box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.7);
        box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.7);
        &--detail {
            ul {
                list-style: none;
                li {
                    color: var(--white);
                    font-size: rem(24px);
                    font-weight: 600;
                    @include flex(center, flex-start);
                    margin-bottom: rem(20px);
                    .icon {
                        margin-right: rem(26px);
                        svg {
                            width: 18px;
                            height: auto;
                        }
                    }
                }
            }
            a {
                font-size: rem(24px);
                font-weight: 600;
                color: var(--white);
                @include flex(center, space-between);
                text-transform: uppercase;
                text-decoration: none;
                padding-left: rem(28px);
                transition: 0.4s ease-in all;
                span {
                    border-bottom: 1px solid var(--white);
                    margin-right: 10px;
                }
                .icon {
                    color: #FFF;
                    svg {
                        width: 50px;
                        height: auto;
                        position: relative;
                        left: 0;
                        transition: 0.4s ease-in all;
                    }
                }
                @media screen and (min-width: 1025px) {
                    &:hover {
                        color: var(--secondary);
                        span {
                            border-color: var(--secondary);
                        }
                        svg {
                            left: 5px;
                        }

                        .icon{
                            color: var(--secondary);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 1199px) {
            padding: rem(40px);
        }
        @media screen and (max-width: 991px) {
            flex-direction: column;
            &--detail {
                margin-top: rem(30px);
            }
        }
        @media screen and (max-width: 575px) {
            padding: rem(30px) rem(15px);
            &--detail {
                a {
                    font-size: rem(18px);
                    .icon {
                        svg {
                            width: 35px;
                        }
                    }
                }
            }
        }
    }
}
/deep/ {
    .vuejs-countdown {
        @include flex(center, space-between);
        direction: ltr !important;
        li {
            width: rem(150px);
            height: rem(150px);
            background-color: rgba(255, 255, 255, 0.1);
            border-bottom: 3px solid var(--secondary);
            @include flex(center, center);
            flex-direction: column;
            .digit {
                font-size: rem(57px);
                font-weight: 900;
                color: var(--secondary);
            }
            .text {
                color: var(--white);
                font-size: rem(16px);
                text-transform: uppercase;
            }
            &:after {
                display: none !important;
            }
            &:first-child {
                border-color: var(--secondary);
                .digit {
                    color: var(--secondary);
                }
            }
            &:nth-child(2) {
                border-color: var(--secondary);
                .digit {
                    color: var(--secondary);
                }
            }
            &:nth-child(3) {
                border-color: var(--secondary);
                .digit {
                    color: var(--secondary);
                }
            }
        }
        @media screen and (max-width: 1199px) {
            li {
                width: rem(130px);
                height: rem(130px);
            }
        }
        @media screen and (max-width: 575px) {
            li {
                width: rem(80px);
                height: rem(80px);
                .digit {
                    font-size: rem(32px);
                }
            }
        }
    }
}

// ar style

.ar {
    .workshopsDetail {
        &__venue {
            padding: rem(45px) rem(65px) rem(45px) rem(115px);
            &--detail {
                ul {
                    li {
                        .icon {
                            margin-left: rem(26px);
                            margin-right: 0;
                        }
                    }
                }
                a {
                    padding-right: rem(28px);
                    padding-left: 0;
                    .icon {
                        svg {
                            right: 0;
                            left: auto;
                            transform: rotate(180deg);
                        }
                    }
                    span {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                    @media screen and (min-width: 1025px) {
                        &:hover {
                            svg {
                                right: 5px;
                                left: auto;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1199px) {
                padding: rem(40px);
            }
            @media screen and (max-width: 1199px) {
                padding: rem(30px) rem(15px);
            }
        }
    }
}
</style>
