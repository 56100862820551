import { WorkshopHelper } from '@/common/crud-helpers/workshop'
export const workshopsReviews = {
    methods: {
        social(data) {
            if (data.type == 'like') {
                WorkshopHelper.postWorkshopLike(
                    this.uuid(data.object.uuid),
                ).then(resp => {
                    if (resp.data.id) {
                        data.object.i_like = true
                        data.object.likes_count++
                    } else {
                        data.object.i_like = false
                        data.object.likes_count--
                    }
                })
            } else if (data.type == 'follower') {
                WorkshopHelper.postWorkshopFollower(
                    this.uuid(data.object.uuid),
                ).then(resp => {
                    if (resp.data.id) {
                        data.object.i_follow = true
                        data.object.followers_count++
                    } else {
                        data.object.i_follow = false
                        data.object.followers_count--
                    }
                })
            }
        },
    },
}
