<template>
    <div>
        <hooper ref="carousel" :settings="hooperSettings">
            <slide
                class="slide"
                v-for="(speaker, index) in speakers"
                :key="index"
            >
                <div class="workshopsDetail__speakers--image">
                    <img
                        v-if="speaker.photo"
                        loading="lazy"
                        class="img-fluid"
                        :src="speaker.photo.file"
                        alt=""
                    />
                    <img
                        v-else
                        loading="lazy"
                        class="img-fluid"
                        src="@/assets/images/svg/person.svg"
                        alt=""
                    />
                    <div class="workshopsDetail__speakers--desc">
                        <div class="workshopsDetail-__speakers--name">
                            {{ speaker[`name_${$i18n.locale}`] }}
                        </div>
                        {{ speaker[`job_${$i18n.locale}`] }}
                    </div>
                    <a href="#" class="abs-link"></a>
                </div>
            </slide>
            <hooper-pagination
                slot="hooper-addons"
                mode="fraction"
            ></hooper-pagination>
            <hooper-progress slot="hooper-addons"></hooper-progress>
        </hooper>
    </div>
</template>

<script>
export default {
    props: {
        speakers: Array,
    },
    data() {
        return {
            hooperSettings: {
                variableWidth: false,
                itemsToShow: 4,
                itemsToSlide: 1,
                autoPlay: true,
                playSpeed: 2000,
                wheelControl: false,
                infiniteScroll: false,
                focusOnSelect: true,
                breakpoints: {
                    767: {
                        itemsToShow: 4,
                    },
                    575: {
                        itemsToShow: 3,
                    },
                    0: {
                        itemsToShow: 2,
                    },
                },
            },
        }
    },
    methods: {
        goto(index) {
            this.$refs.carousel.slideTo(index)
        },
    },
}
</script>

<style lang="scss" scoped>
.workshopsDetail {
    &__speakers {
        &--image {
            position: relative;
            margin-right: rem(28px);
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                left: 0;
                top: 0;
            }
            img {
                width: 100%;
                height: 359px;
                object-fit: cover;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    img {
                        opacity: 1;
                        -webkit-animation: flash 1s;
                        animation: flash 1s;
                    }
                }
            }
        }
        &--name {
            font-size: rem(21.4px);
            font-weight: 700;
            color: var(--white);
            text-transform: uppercase;
            letter-spacing: 0;
            line-height: 1.3;
        }
        &--desc {
            color: #dedede;
            font-size: rem(15px);
            text-transform: uppercase;
            letter-spacing: 2px;
            font-weight: 700;
            padding: 0 rem(10px) rem(35px) rem(20px);
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
}
.hooper {
    z-index: 2;
    position: static;
    .hooper-progress {
        margin: auto;
        bottom: -52px;
        top: auto;
        left: 0;
        margin-left: rem(82px);
    }
    .hooper-pagination {
        bottom: -58px !important;
        width: auto !important;
        left: 0px;
        transform: none;
        direction: ltr !important;
        /deep/ {
            span {
                margin-bottom: 0px;
                color: #0c0c0c;
                font-size: rem(18px);
                font-weight: 900;
                &:first-child {
                    padding-left: 0px;
                }
            }
        }
    }
    // .hooper-slide{
    //     padding-right:rem(28px);
    // }
}

// key frame
@-webkit-keyframes flash {
    0% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

// ar style

.ar {
    .workshopsDetail {
        &__speakers {
            &--image {
                margin-left: rem(28px);
                margin-right: 0;
            }
            &--desc {
                padding: 0 rem(20px) rem(35px) rem(10px);
                right: 0;
                left: auto;
            }
        }
    }
    .hooper {
        .hooper-progress {
            margin-right: rem(82px);
            margin-left: 0;
        }
        .hooper-pagination {
            right: 0px;
            left: auto;
            /deep/ {
                > span {
                    padding-right: 10px;
                    padding-left: 0;
                }
            }
        }
    }
}
</style>
