<template>
    <div>
        <!-- Banner -->
        <base-inner-banner :banner-image="pageBanner"></base-inner-banner>
        <!-- Banner -->

        <!-- Scroll Spy -->
        <base-sections-scroll
            :page-sections="pageSections"
        ></base-sections-scroll>
        <!-- Scroll Spy -->
        <div class="workshopsDetail">
            <div class="content-wrapper">
                <div class="container">
                    <div
                        class="page__head"
                        data-aos="fade-up"
                        id="introduction"
                    >
                        <h1 class="page__head--title">
                            {{ workshop[`title_${$i18n.locale}`] }}
                        </h1>
                        <div class="page__head--shortdesc">
                            <p>{{ workshop[`agenda_${$i18n.locale}`] }}</p>
                        </div>
                    </div>
                    <div class="live__streaming">
                        <a
                            v-if="workshop.video_url"
                            :href="workshop.video_url"
                            target="_blank"
                            class="play"
                        ></a>
                        <div v-if="workshop.video_url" class="duration">
                            {{ videoDuration | secondhhmmss }}
                        </div>
                    </div>
                    <div class="page-contents">
                        <div class="workshopsDetail__venue">
                            <!-- Venue -->
                            <venue
                                v-if="workshop.id"
                                :workshop="workshop"
                            ></venue>
                            <!-- Venue -->
                        </div>
                        <div class="workshopsDetail__about" id="about">
                            <div class="row" data-aos="fade-down">
                                <div class="col-lg-4 col-md-5">
                                    <div class="section-title">
                                        <h2>
                                            {{ $t('About sprint') }}
                                        </h2>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-7">
                                    <p>
                                        {{
                                            workshop[
                                                `description_${$i18n.locale}`
                                            ]
                                        }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="workshopSettings && reasonData.length"
                            class="workshopsDetail__reasons"
                        >
                            <div class="position-relative">
                                <div class="greyBg"></div>
                                <div class="section-title" data-aos="fade-up">
                                    <h2>{{ $t('3 reason not to miss it') }}</h2>
                                </div>
                                <!--Reason-->
                                <reason
                                    :reason="reasonData"
                                    :workshopSettings="workshopSettings"
                                ></reason>
                                <!--Reason-->
                            </div>
                        </div>
                        <div class="workshopsDetail__speakers" id="speakers">
                            <div class="position-relative">
                                <div class="greyBg right"></div>
                                <div class="section-title" data-aos="fade-up">
                                    <h3>{{ $t('Speakers') }}</h3>
                                </div>
                                <!--Speakers-->
                                <speakers
                                    v-if="workshop.speakers"
                                    :speakers="workshop.speakers"
                                ></speakers>
                                <!--Speakers-->
                            </div>
                        </div>
                        <div class="workshopsDetail__feedback">
                            <div class="position-relative">
                                <div class="greyBg"></div>
                                <div
                                    class="d-flex align-items-center justify-content-between"
                                    id="reviews"
                                >
                                    <div
                                        class="section-title"
                                        data-aos="fade-up"
                                    >
                                        <h3>{{ $t('Feedback') }}</h3>
                                    </div>
                                    <!-- reivewsummary -->
                                    <base-reviews
                                        v-if="workshop.id"
                                        :object="workshop"
                                        @social="social"
                                    ></base-reviews>
                                    <!-- reivewsummary -->
                                </div>
                                <!-- PostComments -->
                                <base-post-comments
                                    @click="addComment"
                                    :object="workshop"
                                />
                                <!-- PostComments -->

                                <!-- comments -->
                                <base-comments
                                    :object="workshop"
                                    :comments="comments"
                                    :loadding="commentsLoadding"
                                    @loadMore="loadMoreComments"
                                    @addComment="addComment"
                                    @likeComment="likeComment"
                                ></base-comments>
                                <!-- comments -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/workshop-detail-banner-1200X800.png'
import imgLarge from '@/assets/images/banners/workshop-detail-banner-1600X870.png'
import imgBig from '@/assets/images/banners/workshop-detail-banner-1900X800.png'
import { WorkshopHelper } from '@/common/crud-helpers/workshop'
import Reason from '@/modules/workshop/components/details/Reason'
import Speakers from '@/modules/workshop/components/details/Speakers'
import Venue from '@/modules/workshop/components/details/Venue'
import { workshopsAddComment } from '@/modules/workshop/mixins/workshopsAddComment.js'
import { workshopsReviews } from '@/modules/workshop/mixins/workshopsReviews.js'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
export default {
    mixins: [workshopsReviews, workshopsAddComment],
    components: {
        Reason,
        Venue,
        Speakers,
    },
    data() {
        return {
            tabIndex: 0,
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: 'workshopDetail',
            },
            pageSections: [
                {
                    title: this.$t('Introduction'),
                    id: 'introduction',
                },
                {
                    title: this.$t('About sprint'),
                    id: 'about',
                },
                {
                    title: this.$t('Speakers'),
                    id: 'speakers',
                },
                {
                    title: this.$t('Reviews'),
                    id: 'reviews',
                },
            ],
            comments: [],
            reasonData: [],
            commentsLoadding: true,
            workshop: {},
            videoDuration: 0,
        }
    },
    methods: {
        isActive(index) {
            return this.tabIndex == index
        },
        onActivate() {
            // console.log("Receved Event: scrollspy::activate for target ", target);
        },
        loadMoreComments() {
            let next = this.getNextPage(this.workshopCommentsList.next)
            if (!this.commentsLoadding && next) {
                this.commentsLoadding = true
                WorkshopHelper.getWorkshopComments({
                    uuid: this.uuid(this.workshop.uuid),
                    page_size: 6,
                    page: next,
                })
            }
        },
    },
    created() {
        if (this.$route.params.uuid) {
            WorkshopHelper.get(this.$route.params.uuid)
            WorkshopHelper.getWorkshopSettings(this.$route.params.uuid)
        }
        this.$root.$on('scrollspy::activate', this.onActivate)
    },
    computed: {
        ...mapGetters([
            'workshopObject',
            'workshopCommentsList',
            'workshopSettings',
        ]),
    },
    watch: {
        workshopObject() {
            this.commentsLoadding = true
            this.workshop = cloneDeep(this.workshopObject)
            WorkshopHelper.getWorkshopComments({
                uuid: this.uuid(this.workshop.uuid),
                page_size: 6,
            })
            if (this.workshop.video_url) {
                var video = document.createElement('video')
                video.src = this.workshop.video_url
                var _this = this
                video.addEventListener('loadedmetadata', function() {
                    _this.videoDuration = cloneDeep(video.duration.toFixed(2))
                })
            }
        },
        workshopCommentsList() {
            let allComments = _.concat(
                this.comments,
                this.workshopCommentsList.results,
            )
            this.comments = cloneDeep(allComments)
            this.commentsLoadding = false
        },
        workshopSettings() {
            if (this.workshopSettings.reson) {
                this.reasonData = cloneDeep(this.workshopSettings.reson)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
/deep/.page-banner {
    --h: 800px;
    @media screen and (max-width: 1600px) {
        --h: 730px;
    }
    @media screen and (max-width: 991px) {
        --h: 600px;
    }
    @media screen and (max-width: 767px) {
        --h: 465px;
    }
}
.page-contents {
    padding-top: rem(180px);
    @media screen and (max-width: 991px) {
        padding-top: rem(100px);
    }
}
.content-wrapper {
    /deep/.page__head {
        --pagetitle: var(--green);
        &--title {
            margin-bottom: rem(35px);
            font-weight: 900;
            text-transform: none;
        }
        &--shortdesc {
            p {
                color: var(--white);
                font-size: rem(20px);
                line-height: 1.4;
                margin: 0;
                max-width: 600px;
                @include truncate(3, 1.4);
            }
        }
    }
}
.live__streaming {
    .play {
        width: rem(85px);
        height: rem(85px);
        border-radius: 100%;
        background: var(--white);
        @include flex(center, center);
        position: relative;
        margin: rem(40px) 0 rem(25px) 0;
        transition: 0.4s ease all;
        &:after {
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-top: 16px solid transparent;
            border-bottom: 16px solid transparent;
            border-left: 23px solid var(--primary);
            margin-left: rem(7px);
        }
        @media screen and (min-width: 1025px) {
            &:hover {
                background: var(--secondary);
                &:after {
                    border-left-color: var(--white);
                }
            }
        }
    }
    .duration {
        font-size: rem(24px);
        font-weight: 600;
        color: var(--white);
        letter-spacing: 4px;
    }
    @media screen and (max-width: 767px) {
        .play {
            width: rem(64px);
            height: rem(64px);
            &:after {
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 19px solid var(--primary);
            }
        }
    }
}
.workshopsDetail {
    /deep/ {
        .content-wrapper {
            .page {
                &__head {
                    min-height: 200px;
                    // @media screen and (max-width: 1600px) {
                    //     margin-left: rem(150px);
                    // }
                    @media screen and (max-width: 991px) {
                        min-height: 150px;
                    }
                }
            }
        }
        .live__streaming {
            // @media screen and (max-width: 1600px) {
            //     margin-left: rem(150px);
            // }
            // @media screen and (max-width: 991px) {
            //     margin-left: 0;
            // }
        }
    }
    /deep/.section-title {
        h2,
        h3 {
            font-size: rem(52px);
            @media screen and (max-width: 767px) {
                font-size: rem(35px);
            }
            @media screen and (max-width: 414px) {
                font-size: rem(26px);
            }
        }
    }
    &__about {
        margin-top: rem(80px);
        margin-bottom: rem(70px);
        .section-title {
            margin: 0;
            h2 {
                color: var(--secondary);
                font-weight: 900;
                line-height: 1.2;
                margin: 0;
            }
        }
        p {
            color: #333333;
            line-height: 1.6;
            margin: 0;
        }
        @media screen and (max-width: 767px) {
            .section-title {
                h2 {
                    margin-bottom: rem(20px);
                }
            }
        }
    }
    &__reasons {
        margin-top: rem(80px);
        margin-bottom: rem(100px);
        .section-title {
            margin: 0 0 rem(80px) 0;
            h2 {
                color: var(--primary);
                font-weight: 900;
                line-height: 1.2;
                margin: 0;
            }
        }
        /deep/.greyBg {
            --h: 265px;
        }
    }
    &__speakers {
        margin-top: rem(150px);
        margin-bottom: 0;
        /deep/.section-title {
            margin-bottom: rem(70px);
            h3 {
                color: var(--primary);
                font-weight: 900;
                line-height: 1.2;
                margin: 0;
            }
        }
        /deep/.greyBg {
            --h: 650px;
            width: 705px;
            top: rem(-65px);
            @media screen and (max-width: 1199px) {
                --h: 515px;
            }
            @media screen and (max-width: 991px) {
                --h: 450px;
                width: 500px;
            }
        }
    }
    &__feedback {
        margin-top: rem(250px);
        margin-bottom: rem(70px);
        .section-title {
            margin: 0;
            h3 {
                font-weight: 900;
                line-height: 1.2;
                margin: 0;
                color: var(--secondary);
            }
        }
        /deep/.postComments {
            margin-top: rem(55px);
            margin-bottom: rem(50px);
            .form-control {
                color: #464646;
                &::placeholder {
                    color: #464646;
                }
            }
            @media screen and (max-width: 991px) {
                padding-top: rem(12px);
            }
        }
        /deep/.comments {
            background: var(--white);
            padding: rem(40px) 0 rem(40px) rem(40px);
            border: 1px solid #efefef;
        }
        /deep/.comment__row {
            padding-right: rem(60px);
        }
        @media screen and (max-width: 991px) {
            margin-top: rem(190px);
            .d-flex {
                flex-direction: column;
            }
            /deep/.section-title {
                margin-bottom: rem(30px);
            }
        }
        @media screen and (max-width: 767px) {
            /deep/ {
                .comments {
                    padding: rem(20px) 0 rem(20px) rem(20px);
                }
                .comment__row {
                    padding-right: rem(40px);
                }
            }
        }
    }
}

// ar style

.ar {
    .live__streaming {
        .play {
            &:after {
                position: absolute;
                content: '';
                border-right: 23px solid var(--primary);
                border-left: 0;
                margin-right: rem(7px);
                margin-left: 0;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    &:after {
                        border-right-color: var(--white);
                    }
                }
            }
        }
        // @media screen and (max-width: 1600px) {
        //     margin-right: rem(150px);
        //     margin-left: 0;
        // }
        // @media screen and (max-width: 991px) {
        //     margin-right: 0;
        // }
        @media screen and (max-width: 767px) {
            .play {
                &:after {
                    border-right: 19px solid var(--primary);
                }
            }
        }
    }
    .workshopsDetail {
        &__feedback {
            /deep/.comments {
                background: var(--white);
                padding: rem(40px) rem(40px) rem(40px) 0;
                border: 1px solid #efefef;
            }
            /deep/.comment__row {
                padding-left: rem(60px);
                padding-right: 0;
            }
            @media screen and (max-width: 767px) {
                /deep/ {
                    .comments {
                        padding: rem(20px) rem(20px) rem(20px) 0;
                    }
                    .comment__row {
                        padding-left: rem(40px);
                        padding-right: 0;
                    }
                }
            }
        }
        /deep/ {
            .content-wrapper {
                .page {
                    // &__head {
                    //     @media screen and (max-width: 1600px) {
                    //         margin-right: rem(150px);
                    //         margin-left: 0;
                    //     }
                    //     @media screen and (max-width: 991px) {
                    //         margin-right: 0;
                    //     }
                    // }
                }
            }
        }
    }
}
</style>
