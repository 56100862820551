<template>
    <div v-if="workshopSettings && reason.length">
        <div class="row justify-content-center">
            <div
                v-if="reason[0]"
                class="col-lg-4 col-sm-6"
                data-aos="fade-right"
            >
                <div class="workshopsDetail__reason--box img-hover">
                    <div class="workshopsDetail__reason--image">
                        <img
                            loading="lazy"
                            :src="workshopSettings.banner_image.file"
                            alt=""
                        />
                        <div class="workshopsDetail__reason--detail">
                            <div class="icon">
                                <img
                                    loading="lazy"
                                    src="@/assets/images/svg/star.svg"
                                    alt=""
                                />
                            </div>
                            <h3>
                                {{ reason[0].value[`title_${$i18n.locale}`] }}
                            </h3>
                            <p>
                                {{
                                    reason[0].value[
                                        `description_${$i18n.locale}`
                                    ]
                                }}
                            </p>
                            <a
                                :href="
                                    reason[0].value[
                                        `explor_url_${$i18n.locale}`
                                    ]
                                "
                                >{{ $t('Explore More') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="reason[1]"
                class="col-lg-4 col-sm-6"
                data-aos="fade-down"
            >
                <div class="workshopsDetail__reason--box img-hover">
                    <div class="workshopsDetail__reason--image">
                        <img
                            loading="lazy"
                            :src="workshopSettings.banner_image.file"
                            alt=""
                        />
                        <div class="workshopsDetail__reason--detail">
                            <div class="icon">
                                <img
                                    loading="lazy"
                                    src="@/assets/images/svg/star.svg"
                                    alt=""
                                />
                            </div>
                            <h3>
                                {{ reason[1].value[`title_${$i18n.locale}`] }}
                            </h3>
                            <p>
                                {{
                                    reason[1].value[
                                        `description_${$i18n.locale}`
                                    ]
                                }}
                            </p>
                            <a
                                :href="
                                    reason[1].value[
                                        `explor_url_${$i18n.locale}`
                                    ]
                                "
                                >{{ $t('Explore More') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="reason[2]"
                class="col-lg-4 col-sm-6"
                data-aos="fade-left"
            >
                <div class="workshopsDetail__reason--box img-hover">
                    <div class="workshopsDetail__reason--image">
                        <img
                            loading="lazy"
                            :src="workshopSettings.banner_image.file"
                            alt=""
                        />
                        <div class="workshopsDetail__reason--detail">
                            <div class="icon">
                                <img
                                    loading="lazy"
                                    src="@/assets/images/svg/star.svg"
                                    alt=""
                                />
                            </div>
                            <h3>
                                {{ reason[2].value[`title_${$i18n.locale}`] }}
                            </h3>
                            <p>
                                {{
                                    reason[2].value[
                                        `description_${$i18n.locale}`
                                    ]
                                }}
                            </p>
                            <a
                                :href="
                                    reason[2].value[
                                        `explor_url_${$i18n.locale}`
                                    ]
                                "
                                >{{ $t('Explore More') }}</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        reason: Array,
        workshopSettings: Object,
    },
    methods: {},
    created() {},
    computed: {},
    watch: {},
}
</script>

<style lang="scss" scoped>
.workshopsDetail {
    &__reason {
        &--box {
            -webkit-box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.4);
            box-shadow: -4px 6px 12px 0px rgba(0, 0, 0, 0.4);
        }
        &--image {
            position: relative;
            img {
                height: 297px;
                width: 100%;
                object-fit: cover;
            }
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(252, 2, 154, 0.1);
                left: 0;
                top: 0;
            }
        }
        &--detail {
            position: absolute;
            color: var(--white);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            padding: rem(40px);
            .icon {
                color: var(--white);
                text-align: center;
                margin: 0 0 rem(26px) 0;
                svg {
                    width: 38px;
                    height: auto;
                }
                img {
                    width: 38px;
                    height: auto;
                }
            }
            h3 {
                font-size: rem(24px);
                font-weight: 700;
                text-transform: uppercase;
                text-align: center;
                line-height: 1.5;
                min-height: 70px;
                margin-bottom: 5px;
            }
            p {
                font-size: rem(16px);
                text-align: center;
                margin: 0;
                line-height: 1.9;
            }
            a {
                color: var(--white);
                text-transform: uppercase;
                font-weight: 700;
                text-decoration: underline;
                @include flex(center, center);
                padding-top: rem(10px);
                @media screen and (min-width: 1025px) {
                    &:hover {
                        text-decoration: none;
                        opacity: 0.8;
                    }
                }
            }
        }
        @media screen and (max-width: 1199px) {
            &--detail {
                padding: rem(20px);
            }
        }
        @media screen and (max-width: 991px) {
            &--box {
                margin-bottom: rem(30px);
            }
            &--image {
                img {
                    width: 100%;
                }
            }
            &--detail {
                h3 {
                    min-height: 60px;
                }
            }
        }
        @media screen and (max-width: 767px) {
            .row {
                justify-content: center;
            }
            &--box {
                margin-bottom: rem(20px);
            }
            &--detail {
                h3 {
                    min-height: 60px;
                }
            }
        }
        @media screen and (max-width: 575px) {
            &--box {
                max-width: 350px;
                margin: auto auto rem(20px) auto;
            }
        }
    }
}

// ar style

.ar {
    .workshopsDetail {
        &__reason {
            &--image {
                &:before {
                    right: 0;
                    left: auto;
                }
            }
        }
    }
}
</style>
