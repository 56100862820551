import { WORKSHOP, SOCIAL } from '@/modules/workshop/store/actions'

import { ApiServiceHelper } from '../crud-helpers'

export const WorkshopHelper = {
    list(params) {
        return ApiServiceHelper.execAction(WORKSHOP.LIST, params)
    },
    get(params) {
        return ApiServiceHelper.execAction(WORKSHOP.GET, params)
    },
    myWorkshopList(params) {
        return ApiServiceHelper.execAction(WORKSHOP.MY_LIST, params)
    },
    myWorkshopGet(params) {
        return ApiServiceHelper.execAction(WORKSHOP.MY_GET, params)
    },
    getWorkshopSettings(params) {
        return ApiServiceHelper.execAction(WORKSHOP.GET_SETTINGS, params)
    },
    postWorkshopCommentLike(params) {
        return ApiServiceHelper.execAction(SOCIAL.COMMENT_LIKE, params)
    },
    postWorkshopLike(params) {
        return ApiServiceHelper.execAction(SOCIAL.LIKE, params)
    },
    getWorkshopLikes(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_LIKE, params)
    },
    postWorkshopFollower(params) {
        return ApiServiceHelper.execAction(SOCIAL.FOLLOWER, params)
    },
    getWorkshopFollowers(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_FOLLOWER, params)
    },
    postWorkshopComment(params) {
        return ApiServiceHelper.execAction(SOCIAL.COMMENT, params)
    },
    getWorkshopComments(params) {
        return ApiServiceHelper.execAction(SOCIAL.GET_COMMENTS, params)
    },
    userRegistration(params) {
        return ApiServiceHelper.execAction(WORKSHOP.USER_REGISTRATION, params)
    },
    cancelUserRegistration(params) {
        return ApiServiceHelper.execAction(
            WORKSHOP.CANCEL_USER_REGISTRATION,
            params,
        )
    },
    getUserRegistration(params) {
        return ApiServiceHelper.execAction(
            WORKSHOP.GET_USER_REGISTRATION,
            params,
        )
    },
}
