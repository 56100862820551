import { WorkshopHelper } from '@/common/crud-helpers/workshop'
export const workshopsAddComment = {
    methods: {
        addComment(data) {
            WorkshopHelper.postWorkshopComment({
                uuid: this.uuid(data.uuid),
                parent: data.parent ? data.parent : 0,
                comment: data.comment,
                attachment: data.attachment,
            }).then(resp => {
                if (data.commentObject) {
                    data.commentObject.children.push(resp.data)
                } else {
                    this.comments.push(resp.data)
                }
                this.workshop.comments_count++
            })
        },
        likeComment(data) {
            WorkshopHelper.postWorkshopCommentLike(data.id).then(resp => {
                if (resp.data.id) {
                    data.commentObject.i_like = true
                    data.commentObject.likes_count++
                } else {
                    data.commentObject.i_like = false
                    data.commentObject.likes_count--
                }
            })
        },
    },
}
